import React from 'react';
import { Container, Typography } from '@mui/material';
// import Modal from '@mui/material/Modal';

export default function AppointmentSingleViewPage() {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: 400,
  //   bgcolor: 'background.paper',
  //   boxShadow: 24,
  //   p: 4,

  // const [feedbackModal, setFeedbackModal] = useState(false);

  return (
    <>
      <Container
        maxWidth="md"
        sx={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}
      >
        <Typography variant="h4" color="red">
          Appointment single view page
        </Typography>
      </Container>
    </>
  );
}
